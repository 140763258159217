<template>
  <main-layout v-if="!loading">
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>

    <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      :multi-line="true"
      vertical
      right
    >
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.show = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </main-layout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout'
import { mapState, mapActions } from 'vuex'

export default {
  components: { MainLayout },
  data() {
    return {
      loading: true
    }
  },

  computed: {
    ...mapState({
      isAuthenticated: state => state.auth.isAuthenticated,
      snackbar: state => state.snackbar
    })
  },

  methods: {
    ...mapActions({
      getAuthenticatedUser: 'auth/getAuthenticatedUser'
    })
  },

  created() {
    if (this.isAuthenticated) {
      this.getAuthenticatedUser().then(res => {
        this.loading = false
      })
    } else {
      this.$router.replace({ name: 'auth.login' })
    }
  }
}
</script>
