<template>
  <v-app>
    <v-navigation-drawer v-model="controls.drawer" width="322px" floating app>
      <v-layout
        align-center
        justify-center
        column
        class="app-logo-container"
        style="height:322px"
      >
        <div class="app-logo-sheet">
          <v-img
            :src="require('@/assets/images/mmr.png')"
            width="95px"
            height="80px"
            class="ma-6"
            contain
          ></v-img>
        </div>
        <div class="app-company--name mt-8">Manage My Reno</div>
      </v-layout>

      <v-divider />

      <v-list class="app-nav" flat>
        <template v-for="(item, itemIndex) in navItems">
          <v-list-group
            v-if="item.children"
            v-model="item.active"
            :class="{ 'grey lighten-5': item.active }"
            :key="itemIndex"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="pa-2 nav-item" v-text="item.label" />
              </v-list-item-content>
            </template>

            <v-list-item
              class="app-main-nav"
              active-class="white primary--text"
              v-for="(subItem, subItemmIndex) in item.children"
              :key="subItemmIndex"
              :to="subItem.to"
              :href="subItem.href"
              v-ripple="{ class: 'primary--text lighten-4' }"
              @click="open(item)"
              ripple
              link
            >
              <v-list-item-content>
                <v-list-item-title
                  class="pa-2 pl-5 nav-item"
                  v-text="subItem.label"
                />
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <template v-else>
            <v-list-item
              class="app-main-nav"
              active-class="white primary--text"
              v-ripple="{ class: 'primary--text lighten-4' }"
              :key="itemIndex"
              :to="item.to"
              :href="item.href"
              @click="open(item)"
              link
            >
              <v-list-item-content>
                <v-list-item-title class="pa-2 nav-item">
                  {{ item.label }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </v-list>

      <template v-slot:append>
        <v-list>
          <v-menu top offset-y>
            <template v-slot:activator="{ on }">
              <v-list-item two-line v-on="on">
                <v-list-item-avatar>
                  <user-avatar size="40" :avatar="user.avatar" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ user.full_name }}</v-list-item-title>
                  <v-list-item-subtitle>
                    {{ user.email ? user.email : user.phone_number }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list>
              <v-list-item
                :to="{ name: 'user.details', params: { id: user.id } }"
              >
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item>
              <v-list-item @click="logoutConfirmDialog = true">
                <v-list-item-title>Log out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-main class="main-content">
      <div class="px-xl-12 px-lg-10 px-md-6 px-sm-4 px-xs-2">
        <slot />
      </div>
    </v-main>

    <ConfirmModal
      v-model="logoutConfirmDialog"
      title="Logout"
      message="Are you sure you want to logout?"
    >
      <v-btn text @click="logoutConfirmDialog = false">Cancel</v-btn>
      <v-btn color="primary" text @click="logout">Logout</v-btn>
    </ConfirmModal>
  </v-app>
</template>

<script>
import ControlsMixin from '@/utils/mixins/Controls'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { mapState, mapActions, mapGetters } from 'vuex'
import UserAvatar from '@/components/UserAvatar'
import { each } from 'lodash'

export default {
  name: 'MainLayout',

  components: {
    UserAvatar,
    ConfirmModal
  },

  mixins: [ControlsMixin],

  data() {
    return {
      navItems: [
        { label: 'Users', to: { name: 'users' } },
        { label: 'Packages', to: { name: 'packages' } },

        {
          label: 'Resources',
          active: false,
          children: [
            {
              label: 'Milestone Resources',
              to: { name: 'milestone.resources' }
            },
            {
              label: 'Buy a New Home Resources',
              to: { name: 'newhome.resources' }
            }
          ]
        },

        {
          label: 'Questionaires',
          active: false,
          children: [
            {
              label: 'Onboarding Questions',
              to: { name: 'onboarding-questions' }
            },
            { label: 'Milestone Form Questions', to: { name: 'formQuestions' } }
          ]
        },

        {
          label: 'Pricing Setup',
          active: false,
          children: [
            { label: 'Plans', to: { name: 'plans' } },
            { label: 'Milestones', to: { name: 'milestones' } },
            {
              label: 'Promo Codes',
              href: 'https://dashboard.stripe.com/test/coupons'
            }
          ]
        },

        {
          label: 'System Settings',
          active: false,
          children: [
            { label: 'Legalities setup', to: { name: 'settings' } },
            { label: 'Frequently Asked Questions', to: { name: 'faqs' } }
          ]
        }
        // { label: 'Inbox', to: { name: 'inbox' } },
        // { label: 'Content', to: { name: 'content' } },
        // { label: 'Notifications', to: { name: 'notification' } },
        // { label: 'Reports', to: { name: 'reports' } }
      ],
      logoutConfirmDialog: false
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      snackbar: state => state.snackbar
    }),
    ...mapGetters({
      avatarThumb: 'auth/avatarThumb'
    })
  },

  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),

    open(navItem) {
      each(this.navItems, (item, key) => {
        if (item.hasOwnProperty('children') && item.label !== navItem.label) {
          this.$set(this.navItems, key, { ...item, active: false })
        }

        return item
      })
    }
  }
}
</script>
